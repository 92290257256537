import { defineStore } from "pinia";
import {
  fbGetDocs, fbGetDoc, fbGetDownloadURL,
  fbSimpleQuery, fbDeleteDoc, fbSetDoc, fbAddDoc, fbOnValue,
  database
} from "./firebase";
import { ref as realtimeRef, set, get, onValue, child, push, update, query, startAt, endAt, orderByKey, off, orderByChild, equalTo } from "firebase/database";
import { useAuthStore } from '@/store';
import { isEmpty } from 'lodash-es';

export const useBookStore = defineStore("bookStore", {
  // convert to a function
  state: () => ({
    books: [],
    book: null,
    settings: {},
    pages: {},
    contents: {},
    bookmarks: {},
    error: null,
  }),
  getters: {
    getAllBooks: (state) => state.books,
    getBookById: (state) => (id) => state.books?.find((book) => book.id === id),
    getSettingsByBookId: (state) => (id) => state.settings?.[id],
    getBookmarksByBookId: (state) => (id) => state.bookmarks?.[id],
    getContentsByBookId: (state) => (id) => state.contents?.[id],
    getBookmarkByPage: (state) => (bookId, page) => {
      if (!state.bookmarks?.[bookId]) return;
      const bookmarkId = Object
        .keys(state.bookmarks[bookId])
        .find((bookmarkId) => state.bookmarks[bookId][bookmarkId].page === page);
      return bookmarkId ? state.bookmarks?.[bookId]?.[bookmarkId] : null;
    },
    bookError: (state) => state.error,
  },
  actions: {
    async loadBooks() {
      try {
        const books = [];

        // Get public book list
        const publicBooksSnapshot = await fbSimpleQuery({
          collectionName: 'books',
          queryConstraints: {
            where: [
              ['isPublic', '==', true],
            ],
          }
        });
        if (!publicBooksSnapshot.empty) {
          for (const doc of publicBooksSnapshot.docs) {
            const book = { ...doc.data(), id: doc.id };
            books.push(book);
          }
        }

        // Get private book list
        const authStore = useAuthStore();
        // TODO: This could potentially be 100s of books. Paginate.
        const privateBooksSnapshot = await fbSimpleQuery({
          collectionName: `users/${authStore?.user?.uid}/books`,
          queryConstraints: {
            where: [
              ['isPublic', '==', false],
            ]
          }
        });
        if (!privateBooksSnapshot.empty) {
          for (const doc of privateBooksSnapshot.docs) {
            const book = { ...doc.data(), id: doc.id };
            books.push(book);
          }
        }

        this.books = books.length ? books : null;
        this.error = null;
        return this.books;
      } catch (e) {
        this.books = null;
        this.error = e;
        return false;
      }
    },
    async loadBook(bookId) {
      try {
        const publicBook = await fbGetDoc({
          collectionName: "books",
          docId: bookId,
        });
        const authStore = useAuthStore();
        const privateBook = !publicBook && await fbGetDoc({
          collectionName: `users/${authStore?.user?.uid}/books`,
          docId: bookId,
        });
        this.book = publicBook ?? privateBook ?? null;
        this.error = null;
        return this.book;
      }
      catch (e) {
        this.book = null;
        this.error = e;
        return false;
      }
    },
    async loadBookThumbnail(bookId, quality = 'high') {
      // const book = this.getBookById(bookId);
      // if (!book) {
      //   console.error("Book not found");
      //   return false
      // }
      try {
        const filePath = `content/covers/${bookId}/${quality}/cover.png`;
        const url = await fbGetDownloadURL(filePath)
        return url;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async addBookmark(data) {
      // console.log(data);
      const { bookId, page } = data;
      const authStore = useAuthStore();
      if (!authStore?.user || !bookId) return;

      const bookmarksRef = `users/${authStore?.user?.uid}/books/${bookId}/bookmarks`;
      // console.log(`Adding bookmark to ${bookmarksRef}`);
      const bookmarkId = await fbAddDoc(bookmarksRef, data);
      this.bookmarks[bookId] = this.bookmarks[bookId] ?? {};
      this.bookmarks[bookId][bookmarkId] = { bookmarkId, ...data };
      return this.bookmarks[bookId][bookmarkId];
    },
    async deleteBookmark(bookmark) {
      const authStore = useAuthStore();
      const { bookId, bookmarkId, page } = bookmark;
      if (!authStore?.user || !bookId) return;

      const bookmarksRef = `users/${authStore?.user?.uid}/books/${bookId}/bookmarks`;
      delete this.bookmarks[bookId][bookmarkId];
      // delete by page just in case
      for (const bookmarkId of Object.keys(this.bookmarks[bookId])) {
        if (this.bookmarks[bookId][bookmarkId].page === page) {
          delete this.bookmarks[bookId][bookmarkId];
        }
      }
      await fbDeleteDoc(bookmarksRef, bookmarkId);
    },
    async loadBookmarks(bookId, forceServer = false) {
      try {
        const authStore = useAuthStore();
        if (!authStore?.user || !bookId) return;

        const bookmarksRef = `users/${authStore?.user?.uid}/books/${bookId}/bookmarks`;
        const bookmarksSnapshot = await fbSimpleQuery({
          collectionName: bookmarksRef,
          queryConstraints: {
            orderBy: [
              ["page"]
            ]
          },
          forceServer
        });

        if (!bookmarksSnapshot?.empty) {
          this.bookmarks[bookId] = {};
          for (const doc of bookmarksSnapshot.docs) {
            const bookmark = { ...doc.data(), bookmarkId: doc.id };
            this.bookmarks[bookId][bookmark.id] = bookmark;
          }
        }
        return this.bookmarks;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async loadBookmark(bookmarkId, bookId) {
      const authStore = useAuthStore();
      if (!authStore?.user || !bookmarkId || !bookId) return;
      const bookmarksRef = `users/${authStore?.user?.uid}/books/${bookId}/bookmarks`;
      const bookmark = await fbGetDoc({
        forceServer: true,
        collectionName: bookmarksRef,
        docId: bookmarkId
      });
      this.bookmarks[bookId] = this.bookmarks[bookId] ?? {};
      this.bookmarks[bookId][bookmarkId] = { ...bookmark.data(), bookmarkId };
      return this.bookmarks[bookId][bookmarkId];
    },
    async loadContents(bookId, forceServer = false) {
      try {
        const authStore = useAuthStore();
        if (!authStore?.user || !bookId) return;

        const book = this.getBookById(bookId);
        let contentsRef = `books/${bookId}/contents`;
        if (book.isPublic === false) {
          contentsRef = `users/${authStore?.user?.uid}/books/${bookId}/contents`;
        }
        console.log(contentsRef);
        const contentsSnapshot = await fbSimpleQuery({
          collectionName: contentsRef,
          queryConstraints: {
            orderBy: [
              ["page"]
            ]
          },
          forceServer
        });

        if (!contentsSnapshot?.empty) {
          this.contents[bookId] = {};
          for (const doc of contentsSnapshot.docs) {
            const content = { ...doc.data(), contentId: doc.id };
            this.contents[bookId][content.id] = content;
          }
        }
        return this.contents;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
  persist: true,
});


export const useSessionStore = defineStore("sessionStore", {
  // convert to a function
  state: () => ({
    sessions: {},
    error: null,
    unsubscribe: null,
  }),
  getters: {
    getAllSessions: (state) => state.sessions,
    getActiveSession: (state) => {
      for (const sessionId of Object.keys(state.sessions)) {
        const session = { id: sessionId, ...state.sessions[sessionId] };
        if (session.isActive && session.endAt > Date.now()) return session;
      }
    },
    getUpcomingSessions: (state) => {
      const sessions = {};
      for (const sessionId of Object.keys(state.sessions)) {
        const session = state.sessions[sessionId];
        if (session.isActive || session.endAt < Date.now()) continue;
        sessions[sessionId] = session;
      }
      return sessions;
    },
    sessionError: (state) => state.error,
  },
  actions: {
    loadSessions() {
      try {
        this.sessions = {};
        const authStore = useAuthStore();
        const sessionsRef = realtimeRef(database, `sessions`);
        const sessionQuery = query(sessionsRef, orderByChild("uid"), equalTo(authStore?.user?.uid));
        this.unsubscribe = onValue(sessionQuery, (snapshot) => {
          console.log(snapshot);
          this.sessions = {};
          snapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();
            if (childData.endAt > Date.now()) {
              this.sessions[childKey] = { id: childKey, ...childData };
            }
          });
        })
      } catch (error) {
        this.sessions = null;
        this.error = error;
        return false;
      }
    },
    getSessionById(compositeId, callback) {
      console.log(`Getting session ${compositeId}`);
      try {
        const sessionsRef = realtimeRef(database, `sessions`);
        const sessionQuery = query(sessionsRef, orderByKey(), equalTo(compositeId));
        return onValue(sessionQuery, callback)
      } catch (error) {
        console.error(error);
      }
    },
    getParticipantsById(compositeId, callback) {
      console.log(`Getting participants for session ${compositeId}`);
      try {
        const participantsRef = realtimeRef(database, `participants/${compositeId}/participants`);
        return onValue(participantsRef, callback);
      } catch (error) {
        console.error(error);
      }
    },
    unsubscribeSessions() {
      if (this.unsubscribe && typeof this.unsubscribe === 'function') {
        this.unsubscribe?.();
      }
      this.unsubscribe = null;
    }
  }
});