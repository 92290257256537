import { initializeApp } from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import {
  getFirestore,
  getDoc,
  getDocFromCache,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  getDocs,
  getDocsFromCache,
  collection,
  collectionGroup,
  onSnapshot,
  query,
  where,
  orderBy,
  startAt,
  startAfter,
  endAt,
  limit,
  limitToLast,
  enableIndexedDbPersistence,
} from "firebase/firestore";
import {
  getAuth,
  signOut,
  // signInWithEmailAndPassword,
  // createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { getStorage, ref as firestoreRef, listAll, getDownloadURL } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref as realtimeRef, set, get, onValue, child, push, update } from "firebase/database";


import { Storage as LocalStorage } from '@capacitor/storage';
import { addHours } from 'date-fns';
import { createMD5 } from 'hash-wasm';

// console.table(process.env);
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const database = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app, "asia-east2");
const analytics = getAnalytics(app);

// if (localStorage has lastUpdated) {
//   if (lastUpdated less than 6 hours) {
//     use cache
//   }
//   else {
//     use server
//   }
// }
// else {
//   use server
// }

export const getCustom = async ({ query, reference, forceServer } = {}) => {
  if (!query && !reference) return;
  if (query && reference) return;
  // console.log(JSON.stringify(query));

  const hasher = await createMD5();
  hasher.update(JSON.stringify(query || reference));
  const key = hasher.digest();
  // console.log({ key });

  const stringifiedValueObject = await LocalStorage.get({ key });
  const valueObject = stringifiedValueObject.value && JSON.parse(stringifiedValueObject.value);

  const cacheHours = 24;
  const lastUpdated = valueObject?.lastUpdated;
  let fromCache = false;

  if (lastUpdated && addHours(new Date(lastUpdated), cacheHours) > new Date()) {
    fromCache = true;
  }
  else if (!lastUpdated || addHours(new Date(lastUpdated), cacheHours) < new Date()) {
    // set lastUpdated to now
    const valueObject = { lastUpdated: new Date().getTime() };
    LocalStorage.set({ key, value: JSON.stringify(valueObject) });
  }

  let result;
  if (!forceServer && fromCache) {
    if (query) result = await getDocsFromCache(query);
    if (reference) result = await getDocFromCache(reference);
    // console.log(result);
    console.log(`resultFromCache: ${result.size}`);
  }
  if (forceServer || fromCache === false || (fromCache === true && result.size === 0)) {
    if (query) result = await getDocs(query);
    if (reference) result = await getDoc(reference);
    // console.log(result);
    console.log(`resultFromServer: ${result.size}`);
  }
  return result;
};

// export const fbCreateAccount = async (
//   email,
//   password,
//   first,
//   last
// ) => {
//   const response = await createUserWithEmailAndPassword(auth, email, password);
//   // console.log(response);
//   if (response) {
//     await fbSetUserProfile({ first, last, age: 67 });
//     const profile = await fbGetUserProfile();
//     return {
//       user: response.user,
//       profile,
//     };
//   } else {
//     return {
//       user: null,
//       profile: null,
//     };
//   }
// };

// export const fbSignIn = async (email, password) => {
//   const response = await signInWithEmailAndPassword(auth, email, password);
//   // console.log(response);
//   return response;
// };
enableIndexedDbPersistence(firestore)
  .catch((err) => {
    console.error(err);
  });

export const fbSignOut = async () => {
  await signOut(auth);
  return true;
};

export const fbAuthStateListener = (callback) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      callback(user);
    } else {
      // User is signed out
      callback(null);
    }
  });
};

export const fbSetUserProfile = async (uid, profileData) => {
  const user = auth.currentUser;
  if (!user) return console.error("No user is signed in");
  if (user?.uid !== uid) return console.error("User ID does not match");
  const ref = doc(firestore, "users", user?.uid);
  await setDoc(
    ref,
    {
      ...profileData,
      uid: user?.uid,
    },
    { merge: true }
  );
  return true;
};

export const fbGetUserProfile = async () => {
  const user = auth.currentUser;
  // console.log(user);

  const ref = doc(firestore, "users", user?.uid);
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    console.table(docSnap.data());
    return {
      ...docSnap.data(),
      uid: user?.uid,
    };
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!", user?.uid);
    return null;
  }
};

export const fbGetDocs = async ({ collectionName, forceServer }) => {
  const query = collection(firestore, collectionName);
  const querySnapshot = await getCustom({ query, forceServer });
  const results = [];

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    results.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return results;
};

export const fbGetDoc = async ({ collectionName, docId, forceServer }) => {
  const docRef = doc(firestore, collectionName, docId);
  const docSnap = await getCustom({ reference: docRef, forceServer });
  let docData = null;

  if (docSnap.exists()) {
    docData = docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.error("No such document!");
  }

  return docData;
};

export const fbCollectionListener = (collectionName, callback) => {
  const unsubscribe = onSnapshot(
    collection(firestore, collectionName),
    (snapshot) => {
      // ...
      // console.log("Listening To Collection: " + collectionName, snapshot);
      const results = [];
      snapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      callback(results);
    },
    (error) => {
      // ...
      console.error("Error Listening To Collection: " + collectionName, error);
    }
  );
  return unsubscribe;
};

export const fbDocListener = (collectionName, docId, callback) => {
  // console.log(`fbDocListener`,  { collectionName, docId });
  const unsubscribe = onSnapshot(
    doc(firestore, collectionName, docId),
    (doc) => callback(doc),
    (error) => {
      console.log("Error Listening To Doc: " + collectionName, docId, error);
    }
  );
  return unsubscribe;
};

export const fbGetDownloadURLs = async (path, FirebaseStorage = storage) => {
  const listRef = firestoreRef(FirebaseStorage, path);
  const list = await listAll(listRef);
  const promises = list.items.map((item) => getDownloadURL(item));
  const urls = await Promise.all(promises);
  return urls;

}
export const fbGetDownloadURL = async (filePath, FirebaseStorage = storage) => {
  const fileRef = firestoreRef(FirebaseStorage, filePath);
  const url = await getDownloadURL(fileRef);
  return url;
}

export const fbSimpleQuery = async ({
  collectionName,
  collectionGroupName,
  queryConstraints,
  listenerCallback,
  forceServer
}) => {
  // console.log({ collectionName, queryConstraints, listenerCallback });
  try {
    let ref;
    if (collectionGroupName) {
      ref = collectionGroup(firestore, collectionGroupName);
    }
    else if (collectionName) {
      ref = collection(firestore, collectionName);
    }
    const q = query(
      ref,
      ...('where' in queryConstraints ? queryConstraints.where.map(queryConstraint => where(...queryConstraint)) : []), // where
      ...('orderBy' in queryConstraints ? queryConstraints.orderBy.map(queryConstraint => orderBy(...queryConstraint)) : []), // orderBy
      ...('startAt' in queryConstraints ? [startAt(queryConstraints.startAt)] : []), // startAt
      ...('startAfter' in queryConstraints ? [startAfter(queryConstraints.startAfter)] : []), // startAfter
      ...('endAt' in queryConstraints ? [endAt(queryConstraints.endAt)] : []), // endAt
      ...('limit' in queryConstraints ? [limit(queryConstraints.limit)] : []), // limit
      ...('limitToLast' in queryConstraints ? [limitToLast(queryConstraints.limitToLast)] : []), // limitToLast
    );
    if (listenerCallback) {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        listenerCallback(querySnapshot);
      });
      return unsubscribe;
    }
    else {
      return await getCustom({ query: q, forceServer });
    }
  } catch (error) {
    console.error(error);
  }

}

export const fbSetDoc = async ({ collectionName, docId, data, merge }) => {
  const ref = doc(firestore, collectionName, docId);
  await setDoc(ref, data, (merge ? { merge: true } : {}));
  return true;
}

export const fbAddDoc = async (collectionName, data) => {
  const ref = collection(firestore, collectionName);
  const docRef = await addDoc(ref, data);
  return docRef.id;
}

export const fbDeleteDoc = async (collectionName, docId) => {
  const ref = doc(firestore, collectionName, docId);
  await deleteDoc(ref);
  return true;
}

export const fbDocRef = (path, pathSegments) => doc(firestore, path, pathSegments);

export const fbSet = async (path, data) => {
  console.log({ path, data });
  await set(realtimeRef(database, path), data);
}
export const fbUpdate = async (path, data) => {
  await update(realtimeRef(database, path), data);
}

export const fbOnValue = async (path, callback) => {
  const starCountRef = realtimeRef(database, path);
  let unsubscribe;
  try {
    unsubscribe = onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      const key = snapshot.key;
      callback(data, key);
    });
  } catch (error) {
    console.error(error);
  }
  return unsubscribe;
}

export const fbGet = async (path) => {
  try {
    const dbRef = realtimeRef(database);
    const snapshot = await get(child(dbRef, path));
    if (snapshot.exists()) {
      return snapshot.val();
    }
    return null;
  } catch (error) {
    console.error(error);
  }
}

export const fbPush = async (path, data) => {
  const listRef = realtimeRef(database, path);
  const itemRef = await push(listRef, data);
  return itemRef;
}

export { app, database, firestore, auth, storage, functions, analytics };
