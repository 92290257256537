import { User as fbUser } from "firebase/auth";
import { fbSimpleQuery } from "@/store/firebase";
import { serverTimestamp, increment } from "firebase/firestore";
import { defineStore } from "pinia";
import {
  fbAuthStateListener,
  fbGetUserProfile,
  fbSetUserProfile,
  fbSignOut,
} from "./firebase";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    user: null,
    profile: null,
    error: null,
    config: {},
  }),
  getters: {
    isLoggedIn: (state) => state.user !== null,
    userError: (state) => state.error,
  },
  actions: {
    initializeAuthListener() {
      return new Promise((resolve) => {
        fbAuthStateListener(async (user) => {
          this.user = user ? user : null;

          if (user) {
            const profile = (await fbGetUserProfile());
            this.profile = profile;
          }
          resolve(true);
        });
      });
    },
    // setUser(user) {
    //   try {
    //     this.user = user ? user : null;
    //     this.error = null;
    //     return true;
    //   } catch (e) {
    //     this.user = null;
    //     this.error = e;
    //     return false;
    //   }
    // },
    // async logInUser(email, password) {
    //   try {
    //     const response = await fbSignIn(email, password);
    //     this.user = response.user ? response.user : null;
    //     this.error = null;
    //     return true;
    //   } catch (e) {
    //     this.user = null;
    //     this.error = e;
    //     return false;
    //   }
    // },
    async logoutUser() {
      try {
        await fbSignOut();
        this.user = null;
        this.profile = null;
        this.error = null;
        return true;
      } catch (e) {
        this.error = e;
        return false;
      }
    },
    // async createAccount(email, password, first, last) {
    //   try {
    //     const { user, profile } = await fbCreateAccount(email, password, first, last);
    //     this.user = user ? user : null;
    //     this.profile = profile ? profile : null;
    //     this.error = null;
    //     return true;
    //   } catch (e) {
    //     this.user = null;
    //     this.error = e;
    //     return false;
    //   }
    // },
  },
});
