import { createApp } from "vue";
import { createPersistedState } from 'pinia-plugin-persistedstate'
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { Offline as OfflineIntegration } from "@sentry/integrations";

import { IonicVue, isPlatform } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.scss";

import { createPinia } from "pinia";
import { useAuthStore } from "./store";
const pinia = createPinia();
pinia.use(createPersistedState({
  storage: localStorage,
  // beforeRestore: () => { },
  // afterRestore: () => { },
  serializer: {
    serialize: JSON.stringify,
    deserialize: JSON.parse,
  }
}))

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DisableSwipeBackDirective } from 'v-disable-swipe-back';

const getConfig = () => {
  let config = {};
  if (isPlatform('iphone')) {
    config = {
      ...config,
      swipeBackEnabled: false,
    };
  }
  return config;
};
const app = createApp(App);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: "https://1e5ece438aeb482b8620be2c21ff8a87@o1172556.ingest.sentry.io/6267511",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "esahifa-com.web.app", "esahifa.com", /^\//],
      }),
      new CaptureConsoleIntegration({
        levels: ["error"]
      }),
      new OfflineIntegration({
        // limit how many events will be localled saved. Defaults to 30.
        maxStoredEvents: 110
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

app.directive('disable-swipe-back', DisableSwipeBackDirective);
app.use(IonicVue, getConfig())
app.use(pinia)

// get the store
const store = useAuthStore();

const version = "1.0.0";
if (typeof localStorage !== 'undefined') {
  const localVersion = localStorage.getItem('version');
  if (localVersion !== version) {
    localStorage.clear();
    localStorage.setItem('version', version);
  }
}


// initialize auth listener to see
// if we have a user at startup
store.initializeAuthListener().then(() => {
  app.use(router).mount("#app");
});
