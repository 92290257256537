import { useAuthStore } from "@/store";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { Storage } from '@capacitor/storage';

/**
 *
 * @param to
 * @param from
 * @param next
 */
const authCheck = (to: any, from: any, next: any) => {
  const authStore = useAuthStore();
  if (authStore.isLoggedIn) {
    if (["login", "home"].includes(to.name)) {
      next({ name: "library" });
    } else {
      next();
    }
  } else {
    if (["login", "verify", "home"].includes(to.name)) {
      next();
    } else {
      sessionStorage && sessionStorage.setItem('redirectPath', to.fullPath);
      next({ name: "login" });
    }
  }
};
const authInputCheck = (to: any, from: any, next: any) => {
  Storage.get({ key: "authInput" }).then(({ value }) => {
    if (value) {
      next();
    } else {
      next({ name: "login" });
    }
  });
}

const routes: Array<RouteRecordRaw> = [
  {
    name: "home",
    path: "/",
    component: () => import("@/views/Home.vue"),
    beforeEnter: authCheck,
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/Login.vue"),
    beforeEnter: authCheck,
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("@/views/Verify.vue"),
    beforeEnter: [authCheck, authInputCheck],
  },
  {
    name: "library",
    path: "/library",
    component: () => import("@/views/BookLibrary.vue"),
    beforeEnter: authCheck,
  },
  {
    name: 'book',
    path: '/book/:id',
    component: () => import("@/views/BookReader.vue"),
    beforeEnter: authCheck
  },
  {
    name: 'sessions',
    path: '/sessions',
    component: () => import("@/views/SessionsManager.vue"),
    beforeEnter: authCheck,
  },
  {
    name: 'follow',
    path: '/follow/:id',
    component: () => import("@/views/SessionViewer.vue"),
    beforeEnter: authCheck,
  },
  {
    name: 'add',
    path: '/add',
    component: () => import("@/views/AddBook.vue"),
    beforeEnter: authCheck,
  },
  {
    name: 'feedback',
    path: "/feedback",
    component: () => import("@/views/Feedback.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/home",
    redirect: "/",
  },
  { path: "/:pathMatch(.*)", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
