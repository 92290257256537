<template>
  <ion-app>
    <ion-menu
      content-id="main-content"
      type="overlay"
      id="menu"
      :swipe-gesture="false"
      :disabled="!authStore?.isLoggedIn"
    >
      <ion-header class="menu-header">
        <ion-toolbar color="light">
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-menu-toggle auto-hide="false">
          <ion-item-group v-if="!authStore?.isLoggedIn">
            <ion-item button @click="navigate('/login')">
              <ion-icon slot="start" :icon="personOutline"></ion-icon>
              <ion-label>Log In / Register</ion-label>
            </ion-item>
          </ion-item-group>
          <ion-item-group v-if="authStore?.isLoggedIn">
              <ion-item button @click="navigate('/sessions')">
                <ion-icon slot="start" :icon="radioOutline"></ion-icon>
                <ion-label>Live Sessions</ion-label>
              </ion-item>
              <!-- <ion-item button @click="navigate('/add')">
                <ion-icon slot="start" :icon="bookOutline"></ion-icon>
                <ion-label>Add Book</ion-label>
              </ion-item> -->
              <!-- <ion-item>
                <ion-icon button slot="start" :icon="cloudOutline"></ion-icon>
                <ion-label>Storage & Data</ion-label>
              </ion-item> -->
          </ion-item-group>
        </ion-menu-toggle>
      </ion-content>
      <ion-footer>
        <ion-item-group>
          <ion-item detail="false">
            <ion-icon slot="start" :icon="moonOutline"></ion-icon>
            <ion-label>Dark Mode</ion-label>
            <ion-toggle
              value="dark"
              :checked="getPreferredColorScheme() === 'dark'"
              @ionChange="toggleDarkMode"
            ></ion-toggle>
          </ion-item>
        </ion-item-group>
        <ion-menu-toggle auto-hide="false">
          <ion-item-group>
            <ion-item button detail="false" @click="navigate('/feedback')">
              <ion-icon slot="start" :ios="chatboxOutline" :md="chatboxOutline"></ion-icon>
              <ion-label>Send Feedback</ion-label>
            </ion-item>
            <ion-item
              v-if="authStore?.isLoggedIn"
              lines="none"
              button
              @click="logOut"
              detail="false"
            >
              <ion-icon slot="start" :ios="logOutOutline" :md="logOutOutline"></ion-icon>
              <ion-label>Logout</ion-label>
            </ion-item>
          </ion-item-group>
        </ion-menu-toggle>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-app>
</template>

<script>
import {
  IonApp, IonRouterOutlet, useIonRouter, IonHeader, IonToolbar,
  IonList, IonListHeader, IonNote, IonItemGroup, IonButton,
  IonIcon, IonItem, IonMenu, IonMenuToggle, IonLabel, IonContent, IonSplitPane,
  IonFooter, IonToggle, IonTitle, onIonViewWillEnter,
} from '@ionic/vue';
import { defineComponent, onMounted, provide } from 'vue';
import { useAuthStore, useBookStore, useSessionStore } from '@/store';
import {
  mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, logOutOutline,
  logOutSharp, helpOutline, helpSharp, personAddOutline, personAddSharp, moonOutline, moonSharp,
  logIn, help, personAdd, chatboxOutline, personOutline, bookOutline, radioOutline, cloudOutline,
} from 'ionicons/icons';
import { analytics } from "@/store/firebase";
import { logEvent } from "firebase/analytics";

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonHeader, IonToolbar,
    IonRouterOutlet, IonTitle,
    IonIcon, IonItem, IonMenu, IonItemGroup,
    IonLabel, IonContent, IonFooter, IonToggle,
    IonMenuToggle
  },
  setup() {
    const router = useIonRouter();
    const authStore = useAuthStore();
    const bookStore = useBookStore();
    const sessionStore = useSessionStore();

    // provide
    provide("authStore", authStore);
    provide("bookStore", bookStore);
    provide("sessionStore", sessionStore);

    function navigate(path) {
      router.navigate(path, "forward", "replace")
    }

    function toggleDarkMode(e) {
      const isChecked = e.target.checked;
      const colorScheme = isChecked ? 'dark' : 'light';
      console.log(`Setting color scheme to ${colorScheme}`);
      setColorScheme(colorScheme);
      logEvent(analytics, "color_scheme_selected", {
        type: colorScheme,
      });
    }

    function setColorScheme(scheme) {
      authStore.config.colorScheme = scheme;
      if (scheme === 'dark') {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    }

    function getPreferredColorScheme() {
      return window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    if (window.matchMedia) {
      var colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const preferredColorScheme = getPreferredColorScheme();
      colorSchemeQuery.addEventListener('change', setColorScheme(preferredColorScheme));
    }

    onIonViewWillEnter(() => {
      sessionStore.loadSessions();
    });

    const logOut = async () => {
      await authStore?.logoutUser();
      logEvent(analytics, "logout");
      router.navigate("/login", "back", "replace")
      return
    }

    return {
      navigate,
      authStore,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      logOutOutline,
      logOutSharp, helpOutline, helpSharp, personAddOutline,
      personAddSharp, moonOutline, moonSharp,
      logIn, help, personAdd, chatboxOutline, personOutline, bookOutline, radioOutline, cloudOutline,
      toggleDarkMode, getPreferredColorScheme,
      logOut
    }
  }
});
</script>
<style lang="scss" scoped>
#menu {
  &.md {
    ion-footer {
      &::before {
        display: none;
      }
    }
  }
  .menu-avatar {
    max-height: 32px;
    max-width: 32px;
    border-radius: 25px;
    overflow: hidden;
  }
  .user-snapshot {
    // --padding-top: 15px;
    // --padding-bottom: 15px;
    ion-thumbnail {
      width: 32px;
      height: 32px;
    }
  }
}
ion-grid {
  --ion-grid-width-md: 400px;
  --ion-grid-width-lg: 400px;
  --ion-grid-width-xl: 400px;
}
</style>
<style scss>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>